<template>
    <section>
        <h1>Oooooooops...</h1>
        <div>
            <h2>
                4&#9785;4
            </h2>
            <p>
                The page you want doesn't exist.
            </p>
            <p>
                Click one of the links in the nav.
            </p>
        </div>
    </section>
</template>

<style lang="scss" scoped>
    @import "src/scss/partials/variables";
    @import "src/scss/partials/mixins";

    h2 {
        color: color(blue);
    }

    p {
        font-size: 4rem;
        color: color(blue);
        text-align: center;

        &:last-child::before {
            content: '☝'; //&#9757; //\261D

            // @media screen and(min-width: 768px) {
            //     content: '☜'; //&#9756;
            // }
        }
    }
</style>
